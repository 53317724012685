import React, { useCallback, useMemo, useEffect, useState } from "react";
import { useRequest, useInterval } from "ahooks";
import Panel from "@/components/Panel";
import CustomTable from "@/components/CustomTable";
import { Spin, Button, Input } from "antd";
import { GetUnBindPage, GetRoadOrderDetail } from "@/services/api";
import styles from "./index.module.scss";
import ZoneSelect from "@/components/ZoneSelect";
import ImgSource from "@/assets/img/jianguan/feifazhanyongchewei@2x.png";
import Abutton from "@/components/Abutton";
import NotBoundMobileModal from "./components/NotBoundMobileModal";
import style from "./index.module.scss";
/** 转地址 */
const iframeURL =
  "http://lz.dgjx0769.com/lz/#/UrbanIntegration/EventManage/InspectionReport";
export interface NotBoundMobileProps {
  areaCode?: string;
  // showPicture: (src: string, extraData?: object) => void;
}

const $ = (selector: string) => document.querySelector(selector);

const NotBoundMobile: React.FC<NotBoundMobileProps> = ({ areaCode }) => {
  const [scrollInterval, setScrollInterval] = useState<number | null>(null);
  const [allowRefresh, setAllowRefresh] = useState(true);
  const [searchValue, setSearchValue] = useState<string>();
  const [type, setType] = useState(true);
  const { data = [], refresh, loading, error } = useRequest(
    useCallback(() => {
      return GetUnBindPage(areaCode);
    }, [areaCode]),
    {
      formatResult: (res) => res.data.list,
      refreshDeps: [areaCode],
    }
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const scrollContainer = useCallback(() => {
    const container = $(".parkabnormm-in-table .ant-table-body");
    if (container !== null) {
      const { clientHeight, scrollHeight, scrollTop } = container;
      let height = scrollTop + 1;
      const top = scrollHeight - clientHeight - height;
      if (top <= 0) {
        height = 0;
      }

      if (scrollHeight <= clientHeight) {
        setScrollInterval(null);
      }

      container.scrollTo(0, height);
    }
  }, []);

  const stopTick = useCallback(() => {
    setAllowRefresh(false);
    setScrollInterval(null);
  }, []);

  const startTick = useCallback(() => {
    setAllowRefresh(true);
    setScrollInterval(100);
  }, []);

  useInterval(() => {
    scrollContainer();
  }, scrollInterval);

  useInterval(
    () => {
      //refresh();
    },
    allowRefresh ? 76000 : null
  );

  useEffect(() => {
    const container = $(".parkabnormm-in-table .ant-table-body");
    if (container) {
      const { clientHeight, scrollHeight } = container;
      const isOver = scrollHeight > clientHeight;
      if (isOver) {
        setScrollInterval(100);
      }
    }
  }, [data]);

  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [orderDetails, setOrderDetails] = useState<API.RoadData>();
  const [modalLoading, setModalLoading] = useState(false);

  const { run: fetchOrderDetails } = useRequest<API.BaseResponse<API.RoadData>>(
    GetRoadOrderDetail,
    {
      manual: true,
      onSuccess: (res) => {
        setOrderDetails(res.data);
        setVisible(true);
        setModalLoading(false);
      },
      onError: () => {
        setModalLoading(false);
      },
    }
  );
  const activeUser = [
  {
    mobile: '130****5689',
    stops: 28,
    payNum: 5
  },
  {
    mobile: '131****5690',
    stops: 22,
    payNum: 3
  },
  {
    mobile: '132****5691',
    stops: 5,
    payNum: 5
  },
  {
    mobile: '133****5692',
    stops: 17,
    payNum: 4
  },
  {
    mobile: '134****5693',
    stops: 29,
    payNum: 6
  },
  {
    mobile: '135****5694',
    stops: 31,
    payNum: 5
  },
  {
    mobile: '136****5695',
    stops: 24,
    payNum: 3
  },
  {
    mobile: '137****5696',
    stops: 19,
    payNum: 2
  },
  {
    mobile: '138****5697',
    stops: 27,
    payNum: 5
  },
  {
    mobile: '139****5698',
    stops: 33,
    payNum: 8
  }
]
  const showModel = (record: any) => {
    setSelectedRow(record);
    setModalLoading(true);
    fetchOrderDetails(record.id);
  };
  const [visible, setVisible] = useState(false);
  if (loading) {
    return (
      <Panel title="未绑定手机号列表">
        <div className="fetch_container" style={{ height: 200 }}>
          <Spin />
        </div>
      </Panel>
    );
  }

  if (error) {
    return (
      <Panel title="未绑定手机号列表">
        <div className="fetch_container" style={{ height: 200 }}>
          请求错误
        </div>
      </Panel>
    );
  }

  return (
    <Panel title={type ? "未绑定手机号列表" : "月活跃用户"}>
      <div className={style.parkinglotmore} onClick={() => setType(!type)}>
        {type ? '月活跃用户' : '未绑定手机号列表'}
      </div>
      <div onMouseEnter={stopTick} onMouseLeave={startTick}>
        {type ? (
          <CustomTable
            className="parkabnormm-in-table"
            dataSource={data}
            rowKey={(record) => record.id}
            style={{ height: "196px" }}
            columns={[
              { title: "车牌号", width: 80, dataIndex: "plateNo" },
              {
                title: "泊位号",
                width: 90,
                dataIndex: "berthNo",
              },
              {
                title: "路段",
                width: 90,
                dataIndex: "roadSectionName",
                className: "text-ellipsis",
              },

              {
                title: "操作",
                width: 80,
                render: (item, index) => (
                  <Button type="text" onClick={() => showModel(item)}>
                    <img src="/img/icon_camera_2.png" alt="" />
                  </Button>
                ),
              },
            ]}
            scroll={{ y: 156 }}
          />
        ) : (
          <CustomTable
            className="parkabnormm-in-table"
            dataSource={activeUser}
            rowKey={(record) => record.id}
            style={{ height: "196px" }}
            columns={[
              { title: "手机号", width: 80, dataIndex: "mobile" },
              {
                title: "停车次数",
                width: 90,
                dataIndex: "stops",
              },
              {
                title: "支付笔数",
                width: 90,
                dataIndex: "payNum",
                className: "text-ellipsis",
              },

            ]}
            scroll={{ y: 156 }}
          />
        )}
      </div>
      <NotBoundMobileModal
        visible={visible}
        onClose={() => setVisible(false)}
        orderDetails={orderDetails}
      />
    </Panel>
  );
};
export default NotBoundMobile;
