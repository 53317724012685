import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRequest, useInterval } from "ahooks";
import Panel from "../index";
import ZoneSelect from "@/components/ZoneSelect";
import CustomTable from "@/components/CustomTable";
import { Spin } from "antd";
import { GetunPaidCar } from "@/services/api";
import styles from "./index.module.scss";
import ImgSource from "@/assets/img/jianguan/qianfeicheliang@2x.png";
import Abutton from "@/components/Abutton";
import style from "./index.module.scss";
/** 转地址 */
const iframeURL =
  "http://lz.dgjx0769.com/lz/#/UrbanIntegration/BasicDataManage/CarManage";
export interface KeyCarTableProps {
  areaCode?: string;
}

const $ = (selector: string) => document.querySelector(selector);

const KeyCarTable: React.FC<KeyCarTableProps> = ({ areaCode }) => {
  const [scrollInterval, setScrollInterval] = useState<number | null>(null);
  const [allowRefresh, setAllowRefresh] = useState(true);

  const { data = [], loading, error, refresh } = useRequest(
    useCallback(() => {
      return GetunPaidCar(areaCode, "15");
    }, [areaCode]),
    {
      formatResult: (res) =>
        res.data.map((item, index) => ({ ...item, index })),
      refreshDeps: [areaCode],
    }
  );
  const [type, setType] = useState(true);
  const carData = [
    {
      plateNo: "粤R7H3K9",
      parkNum: 18,
      parkTime: '45'
    },
    {
      plateNo: "粤R2M8P4",
      parkNum: 32,
      parkTime: '120'
    },
    {
      plateNo: "粤R5R9X1",
      parkNum: 7,
      parkTime: '30'
    },
    {
      plateNo: "粤S4T6Y8",
      parkNum: 25,
      parkTime: '90'
    },
    {
      plateNo: "粤P9W3Q7",
      parkNum: 12,
      parkTime: '60'
    },
    {
      plateNo: "粤R1L5N2",
      parkNum: 42,
      parkTime: '180'
    },
    {
      plateNo: "粤G8J4V6",
      parkNum: 5,
      parkTime: '20'
    },
    {
      plateNo: "粤R3K7M9",
      parkNum: 28,
      parkTime: '110'
    },
    {
      plateNo: "粤R6P2T4",
      parkNum: 15,
      parkTime: '75'
    },
    {
      plateNo: "粤R9X1R5",
      parkNum: 37,
      parkTime: '150'
    },
    {
      plateNo: "湘L4Y8W2",
      parkNum: 9,
      parkTime: '40'
    },
    {
      plateNo: "粤R7V3Q9",
      parkNum: 21,
      parkTime: '85'
    },
    {
      plateNo: "粤N2H6P8",
      parkNum: 33,
      parkTime: '130'
    },
    {
      plateNo: "粤P5T9L1",
      parkNum: 14,
      parkTime: '65'
    },
    {
      plateNo: "粤Q8R4X2",
      parkNum: 26,
      parkTime: '100'
    }
  ]
  const scrollContainer = useCallback(() => {
    const container = $(".KeyCar-table .ant-table-body");
    if (container !== null) {
      const { clientHeight, scrollHeight, scrollTop } = container;
      let height = scrollTop + 1;
      const top = scrollHeight - clientHeight - height;
      if (top <= 0) {
        height = 0;
      }

      if (scrollHeight <= clientHeight) {
        setScrollInterval(null);
      }

      container.scrollTo(0, height);
    }
  }, []);

  const stopTick = useCallback(() => {
    setAllowRefresh(false);
    setScrollInterval(null);
  }, []);

  const startTick = useCallback(() => {
    setAllowRefresh(true);
    setScrollInterval(100);
  }, []);

  useInterval(() => {
    scrollContainer();
  }, scrollInterval);

  useInterval(
    () => {
      //  refresh();
    },
    allowRefresh ? 76000 : null
  );

  useEffect(() => {
    const container = $(".KeyCar-table .ant-table-body");
    if (container) {
      const { clientHeight, scrollHeight } = container;
      const isOver = scrollHeight > clientHeight;
      if (isOver) {
        setScrollInterval(100);
      }
    }
  }, [data]);

  const AbuttonBox = useMemo(() => {
    return <Abutton iframeURL={iframeURL} text={""} />;
  }, []);

  if (loading) {
    return (
      <Panel title="欠费车辆">
        <div className="fetch_container" style={{ height: 200 }}>
          <Spin />
        </div>
      </Panel>
    );
  }

  if (error) {
    return (
      <Panel title="欠费车辆">
        <div className="fetch_container" style={{ height: 200 }}>
          请求错误
        </div>
      </Panel>
    );
  }

  return (
    <Panel title={type ? "欠费车辆": "活跃车辆" }>
      <div className={style.parkinglotmore} onClick={() => setType(!type)}>
        {type ? "活跃车辆" : "欠费车辆"}
      </div>
      <div onMouseEnter={stopTick} onMouseLeave={startTick}>
        <div>
          {type ? (
            <CustomTable
              className={`${styles.car_table} KeyCar-table`}
              dataSource={data}
              rowKey={"index"}
              style={{ height: "196px" }}
              columns={[
                {
                  title: "车主手机号",
                  dataIndex: "mobile",
                  render: (text, record) =>
                    `${
                      record.mobile ? record.mobile.slice(0, 7) + `****` : ""
                    }`,
                },
                { title: "车牌号", dataIndex: "plateNo" },
                { title: "欠费金额", dataIndex: "unPayAmount" },
              ]}
              scroll={{ y: 156 }}
            />
          ) : (
            <CustomTable
              className={`${styles.car_table} KeyCar-table`}
              dataSource={carData}
              rowKey={"index"}
              style={{ height: "196px" }}
              columns={[
                {
                  title: "车牌号",
                  dataIndex: "plateNo",
                },
                { title: "停车次数", dataIndex: "parkNum" },
                { title: "停车时长", dataIndex: "parkTime" },
              ]}
              scroll={{ y: 156 }}
            />
          )}
        </div>
      </div>
    </Panel>
  );
};

export default KeyCarTable;
