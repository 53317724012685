import React, {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useRequest, useInterval } from "ahooks";
import { GetCityInfo, GetCityWeather, GetCityAreaInfo ,GetWeather} from "@/services/api";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  useHistory,
} from "react-router-dom";
import Select from "../Select";
import Moment from "moment";
import styles from "./index.module.scss";
import Search from "@/components/Search";
import Login from "@/components/Login";

export type SetareaCode = (val: string) => void;

export type SetAreaCode = (val: string) => void;

const fullScreenMatch = matchMedia("all and (display-mode: fullscreen)");

const Header: React.FC<{
  onCityChange: (city: {
    areaCode: string;
    coordinates: [number, number];
  }) => void;
  onScreenChange?: () => void;
  screenWide?: boolean;
  ref: any;
}> = forwardRef(({ onCityChange, onScreenChange, screenWide }, ref) => {
  const [fullScreen, setFullScreen] = useState(fullScreenMatch.matches);
  const [time, setTime] = useState(Moment());
  const [cityCode, setCityCode] = useState("");
  const [areaCode, setAreaCode] = useState("");
  const [getCityName, setgetCityName] = useState("清远市");
  // times
  const [times, setTimes] = useState("");
  const [days, setDays] = useState("");
  const [week, setWeek] = useState("");

  const { data: cities = [] } = useRequest(GetCityInfo, {
    formatResult: (res) =>
      res.data.map((item) => ({
        label: item.cityName,
        value: item.areaCode,
        coordinates: [Number(item.lng), Number(item.lat)],
      })),
  });
  const onSearch = (keyword: string, searchType: number) => {};

  const { data: areaDataList = [] } = useRequest(
    useCallback(() => {
      // return GetCityAreaInfo("441800");
      return GetCityAreaInfo("441800");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, ["441800"]),
    {
      formatResult: (res) => {
        const citydata = {
          label: "清远市",
          value: "",
          // coordinates: [Number("105.440834"), Number("28.881469")], //116.397128  39.916527
          coordinates: [Number("113.06"), Number("23.70")], //116.397128  39.916527
        };
        let newdata = res.data.map((item) => ({
          label: item.areaName,
          value: item.areaCode,
          coordinates: [Number(item.lng), Number(item.lat)],
        }));
        newdata.unshift(citydata);
        console.log(newdata)
        return newdata;
      },
    }
  );
  const { data: weather, loading: weatherLoading } = useRequest<API.BaseResponse<API.WeatherForecast>>(useCallback(() => {
    return GetWeather(areaCode);
  },[areaCode]),
  {
    formatResult: (res) => {
      return res.data;
    }
  }
  )
  
  const cityChange = (value: string) => {
    const data = areaDataList.find((item) => item.value === value);
    setAreaCode(value);
    setgetCityName(data?.label as string);
    onCityChange({
      areaCode: data?.value as string,
      coordinates: data?.coordinates as [number, number],
    });
  };

  const fullScreenChange = () => {
    if (fullScreenMatch.matches) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  };
  useInterval(() => {
    // setTime(Moment());

    // 没隔一秒就更新一次时间数据
    setTimes(Moment().format("l").replace(/\//g, "-"));
    setDays(Moment().format("dddd"));
    setWeek(Moment().format("LT"));
  }, 1000);

  useEffect(() => {
    // const query = matchMedia('all and (display-mode: fullscreen)');
    fullScreenMatch.onchange = () => setFullScreen(fullScreenMatch.matches);
  });
  useImperativeHandle(ref, () => ({
    setAreaCode,
  }));
  return (
    <div
      className={`${styles.headerbox} ${screenWide ? styles.header_wide : ""}`}
    >
      {/* 时间 */}
      <div className={`${styles.times_bg}`}>
        <div className={`${styles.times_box}`}>
          {times}
          <em style={{ margin: "0 8px" }}></em>
          {days}
          <em style={{ margin: "0 8px" }}></em>
          {week}
          <em style={{ margin: "0 8px" }}></em>
          {weatherLoading ? "暂无" : (weather?.weather || "暂无")}
        </div>
      </div>
      {/* 时间 */}
      <div
        className={`${styles.system_bg}`}
        onClick={() => {
          window.open(
            // "https://web.lzzhtc.com/ims-operating/#/login?redirect=%2F"
            "https://webv2.dgjx0769.com/ims-operating/#/login?redirect=%2F"
          );
        }}
      >
        <div className={`${styles.system_box}`}>系统模块 </div>
      </div>
      {/* 标题 */}
      <div className={`${styles.maptitle_box}`}>
        <div className={`${styles.maptitle_bg}`}></div>
        <div className={`${styles.maptitle}`}>
          {/* {`${getCityName}`}静态交通大数据可视化平台 */}
          可视化决策分析平台
        </div>
      </div>

      <div className={`${styles.btn_diamond}`} style={{textAlign:'center'}}>
        {/* <Select
          className={styles.city_select}
          dropdownMatchSelectWidth={182}
          options={areaDataList}
          value={areaCode}
          onChange={cityChange}
        /> */}
        {areaDataList[0]?.label || '清远市'}
      </div>
    </div>
  );
});

export default Header;
